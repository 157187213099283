<template>
  <div
    style="position: fixed; top: 50%;left: 50%;transform: translate(-50%, -50%);"
  >
    <slot name="body">
      <el-row type="flex" class="row-bg" justify="center">
        <el-col :span="24">
          <div class="grid-content">
            <div>
              <slot name="icon">
                <core-animated-checkmark style="margin-bottom: 2em" />
              </slot>
            </div>

            <h1>
              <slot name="title">{{
                $t("entity_saved_correctly", {
                  entity: $tc("order").toLocaleLowerCase()
                })
              }}</slot>
            </h1>
            <p>
              <slot name="description">{{
                $t("page_closing_in_entity", {
                  entity: countDown
                })
              }}</slot>
            </p>
          </div>
        </el-col>
      </el-row>
    </slot>
  </div>
</template>

<script>
export default {
  name: "PaymentOrderRedirect",
  data: () => ({
    countDown: 5
  }),
  mounted: function() {
    if (!window.opener && process.env.NODE_ENV !== "development")
      this.$router.replace({ path: "/" });

    this.init();
  },
  watch: {
    countDown: val => {
      if (val <= 0 && !!window.opener) {
        window.opener.postMessage("payment:close", window.location.origin);
        window.close();
      }
    }
  },

  methods: {
    init() {
      this.message();
      this.countdown();
    },
    message: function() {
      if (window.opener)
        window.opener.postMessage("payment:success", window.location.origin);
    },
    countdown: function() {
      let timer = setInterval(() => {
        this.countDown -= 1;
        if (this.countDown <= 0) {
          clearInterval(timer);
        }
      }, 1500);
    }
  }
};
</script>

<style scoped type="scss">
h1,
h2,
h3,
p {
  text-align: center;
}

h1,
h2,
h3 {
  color: #157efb;
}
</style>
